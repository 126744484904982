@import "../normalize.css";
@import "../components/all.css";
@import "../modifiers.css";

main{
  padding-top: 0;
}
body{
  place-content: center ;
}
.background-main{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
}
.background-main *{
  position: absolute;
  pointer-events: none;
  z-index: -1;
}

.background-main svg{
  width: 100%;
  height: min-content;
}
.background-main svg:nth-child(1){
  bottom:0;
  right: 0;
}
.background-main svg:nth-child(2){
  bottom:0;
  right: 0;
}
.background-main svg:nth-child(3){
  bottom:0;
  right: 0;
}
.main-container{
  gap: 0;
}